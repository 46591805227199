/* Customized Defaults */
$primary: #000;
$secondary: #000;
$tertiary: #395e6a; // lighter navy
$dark: #212529;
$black: #000;

// search for and replace later:
// 2B464F
// 4A7482

$active: #F4803C; // orange
$active-inverse: #fff;

$body-bg: #213944;
$body-color: #fff;

$font-family-sans-serif:      "Lato";
$font-size-base:              .875rem;
$h1-font-size:                $font-size-base * 2.0;
$h2-font-size:                $font-size-base * 1.8;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base * 0.75;

$list-group-action-active-color:    'white';
$list-group-action-active-bg:       'black';

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');
@import '../global.scss';
@import '../animation.scss';
@import '../form.scss';
@import '../slider.scss';

.yah-icon { height: 22px; margin: -6px 9px 0; }

.quick-links {
  border: 1px solid $tertiary;
  background-color: $body-bg;
  color: $body-color;
  padding: .5rem 1rem;
  margin: 3px;
  text-transform: uppercase;
}

.quick-links.active {
  background-color: $active;
  border: 1px solid $active;
  color: $active-inverse;
  font-weight: bold;
}

.nav-pills .nav-link {
  background-color: $body-bg;
  color: $body-color;
  border: 1px solid #4A7482;
  border-radius: 0;
  text-transform: uppercase;
}

.nav-pills .nav-link.active {
  background-color: $active;
  border: 1px solid $active;
  color: $active-inverse;
  font-weight: bold;
}

ul.directory {
  list-style-type: none;
  padding-left: 0;
}

li.listing {
  border-bottom: 1px solid #ccc;
  padding: 12px 6px;
  overflow: auto;
  cursor: pointer;
  border-bottom: 1px solid #2B464F;
  -webkit-user-select: none;
     -moz-user-select: -moz-none;
      -ms-user-select: none;
          user-select: none;
}

li.listing .active {
  background-color: $active;
}

.form-control, .form-control:focus {
  background-color: #2B464F;
  color: #fff;
  border: none;
  border-radius: 0;
}

.form-control::placeholder { color: #4A7482 }

.simple-keyboard.customTheme .hg-button {
  background-color: var(--keyboard-btn);
  color: var(--keyboard-btn-inverse);
  border: 1px solid var(--keyboard-btn-border);
  border-radius: 0;
}

.simple-keyboard.customTheme .hg-button:active {
  background: var(--keyboard-btn-active);
  color: var(--keyboard-btn-active-inverse);
}

/* https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x#comment62577617_20804016 */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 1em;
  border: 2px solid #4A7482;
  background-color: #4A7482;
  background-position: center center;
  background-size: 20px 20px;
  background-image: -webkit-gradient(linear, left top, right bottom, from(transparent), color-stop(0.46, transparent), color-stop(0.46, #2B464F), color-stop(0.54, #2B464F), color-stop(0.54, transparent), to(transparent)), -webkit-gradient(linear, right top, left bottom, from(transparent), color-stop(0.46, transparent), color-stop(0.46, #2B464F), color-stop(0.54, #2B464F), color-stop(0.54, transparent), to(transparent)) !important;
}
